"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const infrastructure_1 = require("./infrastructure");
const domain_1 = require("./domain");
const events_1 = require("./events");
const NOTIFICATION_MANAGER_QUEUE_IS_INITIALIZED_FLAG = "notificationManagerQueueIsInitialized";
class Main {
    get isInitialized() {
        return window[NOTIFICATION_MANAGER_QUEUE_IS_INITIALIZED_FLAG] === true;
    }
    set isInitialized(value) {
        window[NOTIFICATION_MANAGER_QUEUE_IS_INITIALIZED_FLAG] = value;
    }
    constructor() {
        this.eventAggregationService = new infrastructure_1.WindowEASInstance();
    }
    initialize() {
        if (this.isInitialized)
            return;
        this.isInitialized = true;
        const notificationManagerQueue = new domain_1.NotificationManagerQueue();
        this.eventAggregationService.publishTo(events_1.NotificationManagerQueueReadyEvent.EventType, new events_1.NotificationManagerQueueReadyEvent(notificationManagerQueue));
    }
}
const main = new Main();
exports.default = main;
