"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationComponent = void 0;
class NotificationComponent {
    get element() {
        return this._notificationElement;
    }
    set element(element) {
        this._notificationElement = element;
    }
    constructor(config) {
        this.content = config.content;
        this.notificationType = config.notificationType;
        this.closeAction = config.closeAction;
        this.timer = config.timer;
        this.image = config.image;
        this.confirmAction = config.confirmAction;
        this.wrapperClass = config.wrapperClass;
    }
    generateNotification() {
        const notification = this.createElement('div', ['Notification', 'slide-in', 'js-notification']);
        if (this.wrapperClass) {
            notification.classList.add(this.wrapperClass);
        }
        const notificationWrapper = this.createWrapper();
        const container = this.createContainer();
        const content = this.createContent();
        container.appendChild(content);
        notificationWrapper.appendChild(container);
        notification.appendChild(notificationWrapper);
        if (this.timer) {
            const timerBar = this.createTimer();
            notification.appendChild(timerBar);
        }
        this.element = notification;
        return notification;
    }
    createTimer() {
        const timerBarProgress = this.createElement('div', ['TimerBarProgress']);
        const timerBar = this.createElement('div', ["TimerBar", this.notificationType]);
        timerBarProgress.appendChild(timerBar);
        // allow the notification to fully appear before timer starts
        setTimeout(() => {
            this.startTimer(timerBar);
        }, 1200);
        return timerBarProgress;
    }
    startTimer(timerBar) {
        if (this.timer) {
            const end = Date.now() + this.timer;
            const frame = () => {
                var _a;
                const timeleft = Math.max(0, end - Date.now());
                const timeBarWidth = (100 * timeleft) / ((_a = this.timer) !== null && _a !== void 0 ? _a : 1);
                timerBar.style.width = `${timeBarWidth}%`;
                if (timeleft === 0) {
                    this.closeAction.action(this);
                    return;
                }
                requestAnimationFrame(frame);
            };
            requestAnimationFrame(frame);
        }
    }
    createElement(type, classNames, html) {
        const element = document.createElement(type);
        if (classNames && classNames.length > 0) {
            classNames.forEach(name => element.classList.add(name));
        }
        if (html) {
            element.innerHTML = html;
        }
        return element;
    }
    createWrapper() {
        const notification = this.createElement("div", ["NotificationWrapper"]);
        if (!this.timer) {
            const accent = this.createElement("div", ["NotificationAccent", this.notificationType]);
            notification.appendChild(accent);
        }
        return notification;
    }
    createContainer() {
        const container = this.createElement("div", ["NotificationContainer"]);
        if (this.image) {
            const image = this.createElement("img", ["NotficationImage"]);
            image.setAttribute("src", this.image);
            container.appendChild(image);
        }
        return container;
    }
    createContent() {
        const content = this.createElement("div", ["NotificationContent"]);
        const buttonContainer = this.createButtons();
        const htmlContent = this.createElement("div", ["NotificationText"]);
        htmlContent.innerHTML = this.content;
        content.appendChild(htmlContent);
        content.appendChild(buttonContainer);
        return content;
    }
    createButtons() {
        const buttonContainer = this.createElement("div", ["NotificationButtons"]);
        const closeButton = this.createActionButton(this.closeAction, "Close");
        buttonContainer.appendChild(closeButton);
        if (this.confirmAction) {
            const confirmButton = this.createActionButton(this.confirmAction, "Confirm");
            buttonContainer.appendChild(confirmButton);
        }
        return buttonContainer;
    }
    createActionButton(action, className) {
        var _a;
        const classes = (_a = action.classNames) !== null && _a !== void 0 ? _a : [];
        classes.push(className);
        classes.push("NotificationButton");
        const button = this.createElement("button", classes);
        button.onclick = () => {
            action.action(this);
        };
        if (action.icon) {
            const icon = this.createElement("i", [action.icon]);
            button.appendChild(icon);
        }
        if (action.text) {
            const text = this.createElement("span");
            text.innerHTML = action.text;
            button.appendChild(text);
        }
        return button;
    }
}
exports.NotificationComponent = NotificationComponent;
