"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationManagerQueue = void 0;
const notification_manager_base_1 = require("./notification-manager-base");
class NotificationManagerQueue extends notification_manager_base_1.NotificationManagerBase {
    constructor() {
        super();
        this.notificationsQueue = [];
        this.isThereAnyNotificationOpen = false;
    }
    openNotification(notification) {
        if (notification.element) {
            //avoid queue run (avoiding the onClosedNotification method)
            super.closeNotification(notification);
            return;
        }
        if (this.isThereAnyNotificationOpen) {
            this.notificationsQueue.unshift(notification);
            return;
        }
        this.isThereAnyNotificationOpen = true;
        super.openNotification(notification);
    }
    closeNotification(notification) {
        super.closeNotification(notification, this.onClosedNotification.bind(this));
    }
    onClosedNotification() {
        this.isThereAnyNotificationOpen = false;
        const areAnyNotificationsInQueue = this.notificationsQueue.length > 0;
        if (!areAnyNotificationsInQueue)
            return;
        this.openNotification(this.notificationsQueue.pop());
    }
}
exports.NotificationManagerQueue = NotificationManagerQueue;
