"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationManagerBase = void 0;
class NotificationManagerBase {
    openNotification(notification) {
        const template = notification.generateNotification();
        this.injectNotification(template);
    }
    closeNotification(notification, onClose) {
        if (!notification.element) {
            return;
        }
        this.animateNotificationOut(notification.element);
        // allow animation to complete
        setTimeout(() => {
            this.removeNotificationFromDOM(notification.element);
            notification.element = null;
            if (onClose) {
                onClose();
            }
        }, 1000);
    }
    removeNotificationFromDOM(notificationElement) {
        if (!(notificationElement === null || notificationElement === void 0 ? void 0 : notificationElement.parentNode)) {
            return;
        }
        notificationElement.parentNode.removeChild(notificationElement);
    }
    injectNotification(template) {
        const body = document.querySelector("body");
        if (body !== null) {
            body.appendChild(template);
        }
    }
    animateNotificationOut(notificationElement) {
        notificationElement.classList.remove('slide-in');
        notificationElement.classList.add('slide-out');
    }
}
exports.NotificationManagerBase = NotificationManagerBase;
