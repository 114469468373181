"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationManager = void 0;
const notification_manager_base_1 = require("./notification-manager-base");
class NotificationManager extends notification_manager_base_1.NotificationManagerBase {
    constructor() {
        super();
    }
    openNotification(notification) {
        if (notification.element) {
            this.closeNotification(notification);
        }
        super.openNotification(notification);
    }
    closeNotification(notification) {
        super.closeNotification(notification);
    }
}
exports.NotificationManager = NotificationManager;
