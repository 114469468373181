"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WindowEASInstance = void 0;
class WindowEASInstance {
    static get target() {
        return window["EventAggregationService"];
    }
    subscribeTo(eventType, subscriber, replay) {
        var _a;
        (_a = WindowEASInstance.target) === null || _a === void 0 ? void 0 : _a.subscribeTo(eventType, subscriber, replay);
    }
    publishTo(eventType, event) {
        var _a;
        (_a = WindowEASInstance.target) === null || _a === void 0 ? void 0 : _a.publishTo(eventType, event);
    }
}
exports.WindowEASInstance = WindowEASInstance;
