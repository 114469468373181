"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationManagerQueueReadyEvent = void 0;
const enums_1 = require("../enums");
class NotificationManagerQueueReadyEvent {
    constructor(_notificationManagerQueue) {
        this._notificationManagerQueue = _notificationManagerQueue;
        this.eventType = NotificationManagerQueueReadyEvent.EventType;
        this.notificationManagerQueue = _notificationManagerQueue;
        this.timestamp = new Date();
    }
    static is(event) {
        return event.eventType === NotificationManagerQueueReadyEvent.EventType;
    }
}
exports.NotificationManagerQueueReadyEvent = NotificationManagerQueueReadyEvent;
NotificationManagerQueueReadyEvent.EventType = enums_1.EventType.NOTIFICATION_MANAGER_QUEUE_READY_EVENT;
